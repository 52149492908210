.main {
    width: 100%;
    max-width: 100vw;
}

.introduction-panel {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 665px;
}
.introduction-text {
    width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-top: 181px;
}
.introduction-title {
    display: flex;
}
.introduction-description {
    position: relative;
    left: 200px;
    display: flex;
    text-align: left;
    flex-direction: column;
    max-width: 491px;

}

.socials {
    display: flex;
    gap: 10px;
}

.introduction-images {
    position: absolute;
    right: 0px;
    top: 60px;
}

.my-photo {
    position: absolute;
    top: 85px;
    right: 150px;
    z-index: 4;
}

.skill-icons {
    display: flex;
    width: 100%;
    background-color: #DEB040;
    color: black;
    border: 3px solid white;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 15px;
    padding-bottom: 15px;
}

.icon-container {
    display: flex;
    width: 1000px;
    
    color: black;
    
    align-content: center;
    align-items: center;
    font-size: 40px;
    height: 30px;
    justify-content: space-evenly;
}

i {
    transition: font-size 0.5s ease-in;
    margin: 0px auto;
}

i:hover {
    font-size: 60px;
}

.work {
    display: flex;
    width: 100%;
    justify-content: center;
}
.work-within {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    width: 1000px;
    text-align: left;
}

.my-works {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.img-box {
    display: flex;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    height: 224px;
    width: 320px;
    background: #000000;
}

.about {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-content: center;
    align-items: center;
}

.about-within {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.about-panels {
    display: flex;
    width: 1000px;
    justify-content: center;
    gap: 20px;
}

.about-text {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    width: 555px;
    height: 518px;
    border-top: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-left: 30px solid #01B7FF;
}

.about-text p {
    text-align: left;
}

.about-picture {
    height: 518px;
}

.about-picture img {
    height: 518px;
}
.getintouch {
    display: flex;
    width: 100%;
    justify-content: center;
}

.getintouch-within {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 1000px;
}

.getintouch-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.textfield {
    width: 601px;
}

.message {
    width: 601px;
}
input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 10px;

    width: 558px;
    height: 30px;

    background: #F5F5F5;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #000000;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #818181;
    margin-bottom: 10px;
}

textarea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;

    width: 558px;
    height: 218px;

    background: #F5F5F5;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #000000;

    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #818181;

    margin-bottom: 10px;
}

button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    width: 157px;
    height: 47px;


    background: #000000;
    box-shadow: 0px 4px 0px #01B7FF;
}

.work-card {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: column;
}

.work-card img {
    max-width: 270px;
}

label {
    text-align: center;
}

.find-me {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 800px) {

    .main {
        position: absolute;
        top: 50px;
    }
    .introduction-panel {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 100%;
        margin-top: 70px;
    }
    .introduction-text {
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
        gap: 10px;
        margin-top: 30px;
    }
    .introduction-title {
        display: flex;
        position: relative;
        right: 20px;
        bottom: 25px;
    }
    .introduction-description {
        position: static;
        display: flex;
        align-items: center;
        align-content: center;
        text-align: left;
        flex-direction: column;
        width: 350px;
    
    }

    .introduction-description p {
        position: relative;
        left: 10px;
    }

    .introduction-images {
        position: absolute;
        right: auto;
        top: 40px;
    }
    .my-photo {
        top: 30px;
        right: auto;
        left: 80px;
        
    }
    .my-photo img {
        max-width: 100px;
    }
    .shapes {
        display: none;
    }

    
    .work-within {
        width: 350px;
        align-items: center;
    }

    .my-works {
        flex-direction: column;
        align-items: center;
        width: 350px;
        gap: 10px;
    }
    .icon-container {
        display: flex;
        width: 100%;
        
        
        
        align-content: center;
        align-items: center;
        font-size: 30px;
        height: 30px;
        justify-content: space-evenly;
    }

    .about-panels {
        flex-direction: column;
        width: 350px;
        align-items: center;
        align-content: center;
    }

    .about-text {
        width: 300px;

        height: 750px;
    }

    input {
        width: 300px;
    }

    textarea {
        width: 300px;
    }

    .textfield {
        width: 350px;
    }

    .message {
        width: 350px;
    }

    .getintouch-within {
        width: 100%;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .getintouch-form {
        align-items: center;
        justify-content: center;
    }
    .skill-icons {
        display: flex;
        width: 100%;
    }
    
    .about-picture img {
        height: auto;
        width: 340px;
    }

    .find-me {
        margin-bottom: 50px;
    }
}