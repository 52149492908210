.App {
  text-align: center;
}

h1 {
  font-family: 'Poppins';
  font-weight: 800;
  font-size: 64px;
  line-height: 96px;
  letter-spacing: 0.02em;
  margin-bottom: -70px;
}

h2 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.02em;
}

h4 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

h5 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.02em;
}

h6 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  margin-bottom: -8px;

}

p {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.02em;
}

