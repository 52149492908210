.nav {
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 42px;
    gap: 96px;
    padding: 18px 46px;
    position: fixed;
    top: 0px;
    z-index: 5;
    background: white;
}

.nav a{
    font-family: 'Poppins';
    text-decoration: none;
    color: black;
    font-weight: 300;
}

.nav a:hover {
    font-weight: 800;
    margin-right: -3px;
}

.main-page-link a{
    font-weight: 800;
}

@media only screen and (max-width: 800px) {
    .nav {
        gap: 20px;
    }
}